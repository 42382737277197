/* Body and Global Settings */
body {
  font-family: 'Papyrus', cursive;
  background: linear-gradient(135deg, #FFF9E6, #FFCCD6); /* Soft pastel gradient */
  color: #2F2F2F;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  animation: backgroundShift 20s infinite alternate;
  position: relative;
}

body.loading-mode {
  overflow: hidden;
}

/* Background Animation */
@keyframes backgroundShift {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

/* Floating Egg Shapes */
.float-egg {
  position: absolute;
  width: 60px;
  height: 80px;
  background: #FFD93D; /* Default egg color */
  border-radius: 50% 50% 60% 60%; /* Egg shape: top narrower, bottom more rounded */
  opacity: 0.5;
  animation: floatShapes 10s ease-in-out infinite;
  z-index: 1;
}

/* Eggs on the left side */
.float-egg:nth-child(1) {
  top: 10%;
  left: 5%;
  background: #FFD93D; /* Yellow Egg */
  animation-duration: 11s;
}

.float-egg:nth-child(2) {
  top: 35%;
  left: 5%;
  background: #FF6B6B; /* Coral Egg */
  animation-duration: 13s;
}

.float-egg:nth-child(3) {
  top: 60%;
  left: 5%;
  background: #6EC5E9; /* Blue Egg */
  animation-duration: 9s;
}

/* Eggs on the right side */
.float-egg:nth-child(4) {
  top: 15%;
  right: 5%;
  background: #F4B400; /* Gold Egg */
  animation-duration: 12s;
}

.float-egg:nth-child(5) {
  top: 45%;
  right: 5%;
  background: #FF69B4; /* Pink Egg */
  animation-duration: 10s;
}

.float-egg:nth-child(6) {
  top: 70%;
  right: 5%;
  background: #00C49A; /* Green Egg */
  animation-duration: 14s;
}

/* Floating Animation */
@keyframes floatShapes {
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  50% {
    transform: translateY(-60px) translateX(30px) rotate(180deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(360deg);
  }
}
/* Main Container */
.app {
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  background: rgba(255, 255, 255, 0.6); /* Glassmorphic effect */
  border-radius: 30px;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-height: 85vh;
  overflow-y: auto;
  backdrop-filter: blur(20px); /* Glass effect */
  border: 2px solid rgba(255, 255, 255, 0.5);
  transition: all 0.4s ease;
}

/* Logo Styling */
.app-logo {
  width: 130px; /* Reduced size */
  margin-bottom: 30px;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(255, 215, 61, 0.7); /* Soft yellow glow */
  transition: transform 0.5s ease-in-out, box-shadow 0.5s;
  animation: float 6s ease-in-out infinite; /* Logo floating effect */
  position: relative;
}

/* Floating Animation */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
}

/* Header Styling with Dynamic Gradient */
h1 {
  font-size: 3.5em;
  font-family: 'Papyrus', cursive;
  background: linear-gradient(135deg, #FFD93D, #FF6B6B);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: colorShift 4s ease-in-out infinite;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3), 0px 0px 10px rgba(255, 215, 61, 0.7);
  letter-spacing: 2px;
}

@keyframes colorShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Input Field Styling */
input[type="text"] {
  width: 70%;
  padding: 15px;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}


ul.storyUl {
  width: 100%; /* Full width of parent */
  max-width: 600px; /* Maximum width for better scaling */
  margin: 0 auto; /* Center horizontally */
  padding: 0;
}

li.storyLi {
  width: 100%; /* Full width for list item */
  margin: 15px 0;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

li.storyLi:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


/* Buttons with Hover Particles */
button {
  padding: 12px 30px;
  font-size: 1.5em;
  background: linear-gradient(135deg, #6EC5E9, #FF6B6B);
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.4s ease, transform 0.4s ease, box-shadow 0.4s ease;
  margin: 15px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
}

button::before {
  content: '';
  position: absolute;
  top: -50px;
  left: -50px;
  width: 200px;
  height: 200px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  border-radius: 50%;
  transition: transform 0.6s, opacity 0.6s;
}

button:hover::before {
  transform: scale(2.5);
  opacity: 1;
}

button:hover {
  background: linear-gradient(135deg, #FFD93D, #FF6B6B);
  transform: scale(1.05);
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.5);
}


/* Story Display */
.story-display {
  margin-top: 30px;
  text-align: left;
}

.story-display p, 
.full-story p {
  background: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 20px;
  font-size: 1.3em;
  color: #2F2F2F;
  transition: transform 0.3s ease, background 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.story-display p:hover, 
.full-story p:hover {
  transform: scale(1.05);
  background: rgba(255, 255, 255, 1);
}

/* Full Story Container */
.full-story {
  margin-top: 30px;
  text-align: left;
  background: rgba(255, 255, 255, 0.85);
  padding: 30px;
  border-radius: 25px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
}

.full-story h2 {
  font-size: 2.2em;
  color: #FFD93D;
  margin-bottom: 20px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

/* Loading Animation */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

.loading-container img {
  width: 100px; /* Slightly smaller */
  height: auto;
  box-shadow: 0 5px 15px rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  animation: pulseShadow 2s infinite;
}

.loading-container p {
  margin-top: 20px;
  font-size: 1.4em; /* Slightly smaller text */
  color: #FF6B6B;
  animation: pulseText 1.5s infinite;
}

/* Pulse Animations */
@keyframes pulseShadow {
  0% {
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.6);
  }
  50% {
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.9);
  }
  100% {
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.6);
  }
}

@keyframes pulseText {
  0% {
    color: #FF6B6B;
  }
  50% {
    color: #FFD93D;
  }
  100% {
    color: #FF6B6B;
}
}

/* Responsive Design */
@media (max-width: 768px) {
  .app {
    padding: 20px;
    width: 90%;
  }

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }

  button {
    font-size: 1.4em;
    padding: 12px 30px;
  }

  select,
  input {
    font-size: 1em;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .app {
    padding: 10px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }

  h1 {
    font-size: 2.2em;
    margin-bottom: 10px;
  }

  button {
    font-size: 1.2em;
    padding: 10px 20px;
  }

  select,
  input {
    font-size: 0.9em;
    padding: 8px 10px;
  }

  .prompt-input h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .character-image img {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 450px) {
  .app {
    padding: 20px;
    max-width: 100%;
    border-radius: 10px;
  }

  h1 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    padding: 10px;
  }

  select, 
  button {
    font-size: 1rem;
    padding: 10px;
    width: 90%; 
    margin: 10px auto;
  }

  .story-type-selection, .prompt-input {
    width: 100%; 
  }

  .story-display p, .full-story p {
    padding: 10px;
    font-size: 1rem;
    border-radius: 10px;
  }

  .story-controls button {
    width: 90%;
    margin: 10px auto;
    font-size: 1.2em;
  }

  .loading-container img {
    width: 100px;
  }

  .loading-container p {
    font-size: 1.2rem;
  }
}
